import React from 'react'
import { Link } from "react-router-dom";
import styled from 'styled-components';
import { theme } from "../GlobalStyle";




const videoDetails = [
    {
        src: '/assets/videos/zimson-cup-branding.mp4',
        link: '/portfolio/branding/ZimsonCup', // Replace with the actual URL or route you want to link to.
    },

]

const Branding = () => {
    return (
        <div className="custom-content custom-active" id="custom-content-1">
            <VideoSection>
                {videoDetails.map((video, index) => (
                    <Link aria-label="Zimson" to={video.link} className="video-ss" key={index}>
                        <video
                            src={process.env.PUBLIC_URL + video.src}
                            autoPlay
                            loop
                            muted
                            playsInline
                            preload="metadata"
                            className="video"
                        >
                        </video>
                    </Link>
                ))}

            </VideoSection>
        </div>
    )
}

const VideoSection = styled.div`
   position: relative;
   margin: 0;
   line-height: 0;
   scroll-snap-type: y mandatory;

   .video-ss {
       line-height: 0 !important;
       padding: 0;
       margin: 0;
       height: 100vh;
       scroll-snap-align: center;
   }

   .video {
       width: 100%;
       height: 100vh;
       object-fit: cover;
       position: sticky;
       line-height: 0 !important;
       top: 0;
   }

   #custom-content-1 .video-section .video {
       height: 100vh;
       position: sticky;
       width: 100%;
   }
   @media (max-width: ${theme.breakpoints.small}) {
       
    .video {
        width: 100%;
        height: 100%;
    }
  }
`;


export default Branding