const digitalInnerPageData = [
    {
        id: 1,
        slug: "ortho-one",
        bannerImage: [
            "/assets/images/digital/ortho-one/banner1.jpg",
            "/assets/images/digital/ortho-one/banner2.jpg",
            "/assets/images/digital/ortho-one/banner3.jpg",
            "/assets/images/digital/ortho-one/banner4.jpg",
        ],

        logoImage: "/assets/images/digital/ortho-one/logo_small.png",
        title: "REVOLUTIONIZING ORTHOPEDIC EXCELLENCE: ORTHO-ONE'S FORAY INTO THE DIGITAL WORLD",
        description: "Logo Design, Branding, UI/UX, Visual Design, Social Media, Web Development & Hosting",
        logovideoText: "The website unveils a tapestry of cutting-edge medical prowess and compassionate patient care through a delicately balanced narrative combined with an intuitively designed user interface. This digital gateway serves as an informative companion, directing patients towards their path to holistic well-being.",
        logovideoSrc: [
            {
                type: "image",
                source: "/assets/images/digital/ortho-one/logo.jpg",
            },

        ],
        sliderImages: [
            {
                type: "image",
                source: "/assets/images/digital/ortho-one/s1.jpg",
            },
            {
                type: "image",
                source: "/assets/images/digital/ortho-one/s2.jpg",
            },
            {
                type: "image",
                source: "/assets/images/digital/ortho-one/s3.jpg",
            },
            {
                type: "image",
                source: "/assets/images/digital/ortho-one/s4.jpg",
            },
        ],

        textSec: "A VOYAGE THROUGH ORTHO-ONE'S DIGITAL REALM OF HEALING",
        scrollableContentImage: "/assets/images/digital/ortho-one/orthoone.jpg",
        mobileImages1: [
            "/assets/images/digital/ortho-one/m1.jpg",
        ],
        mobileImages2: [
            "/assets/images/digital/ortho-one/m2.jpg",
        ],
        mobileImages3: [
            "/assets/images/digital/ortho-one/m3.jpg",
        ],
        logovideoText2: "The client has conveyed immense satisfaction with the website's design and performance, with appreciation for its seamless integration with their philosophy of global outreach extended through personalized care. The user-friendly design and accessibility further enhance the overall commendation for the digital platform.",
        bannerBottomImage: "/assets/images/digital/ortho-one/orthoonebottom.jpg",
        visitLink: "https://ortho-one.in/",
        metaTitle: "Ortho-One Digital Portfolio - Signatures1",
        metaDescription: "Discover how Signatures1 digitally elevated Ortho-One's orthopedic services. Our expertise brought their excellence to a wider online audience.",
        metaogtitle: "Ortho-One Digital Portfolio - Signatures1",
        metaogdescription: "Discover how Signatures1 digitally elevated Ortho-One's orthopedic services. Our expertise brought their excellence to a wider online audience.",
        metaogurl: "https://archive.signatures1.com/digital/ortho-one",
        canonical: "https://archive.signatures1.com/digital/ortho-one",
    },
    {
        id: 2,
        slug: "chennai-rice",
        bannerImage: [
            "/assets/images/digital/chennai-rice/chennairicebanner.jpg",
        ],

        logoImage: "/assets/images/digital/chennai-rice/chennairice_logo_small.png",
        title: "NOURISHING LIVES, ONE GRAIN AT A TIME",
        description: "Logo Design, Branding, UI/UX, Visual Design, Video Shoot, Social Media, Web Development & Hosting",
        logovideoText: "The client has expressed their admiration for the blend of diversity and convenience on the website, captivating users with an array of premium rice products while facilitating effortless navigation.",
        logovideoSrc: [
            {
                type: "image",
                source: "/assets/images/digital/chennai-rice/logo.jpg", // Image source
            },

        ],

        sliderImages: [
            {
                type: "image",
                source: "/assets/images/digital/chennai-rice/s1.jpg",
            },
            {
                type: "image",
                source: "/assets/images/digital/chennai-rice/s2.jpg",
            },
            {
                type: "image",
                source: "/assets/images/digital/chennai-rice/s3.jpg",
            },
            {
                type: "image",
                source: "/assets/images/digital/chennai-rice/s4.jpg",
            },
            {
                type: "image",
                source: "/assets/images/digital/chennai-rice/s5.jpg",
            },
        ],


        textSec: "DISCOVER THE DIGITAL STOREFRONT",
        scrollableContentImage: "/assets/images/digital/chennai-rice/chennairice.jpg",
        mobileImages1: [
            "/assets/images/digital/chennai-rice/m1.jpg",
        ],
        mobileImages2: [
            "/assets/images/digital/chennai-rice/m2.jpg",
        ],
        mobileImages3: [
            "/assets/images/digital/chennai-rice/m3.jpg",
        ],
        logovideoText2: "The client has conveyed immense satisfaction with the website's design and performance, with appreciation for its seamless integration with their philosophy of global outreach extended through personalized care. The user-friendly design and accessibility further enhance the overall commendation for the digital platform.",
        bannerBottomImage: "/assets/images/digital/chennai-rice/chennairicebottom.jpg",
        visitLink: "https://www.chennairice.co.in/",
        metaTitle: "Chennai Rice's Digital Voyage - Partnered with Signatures1",
        metaDescription: "Discover how Signatures1 fueled Chennai Rice's digital journey. Our collaboration transformed their online presence, enhancing brand visibility.",
        metaogtitle: "Chennai Rice's Digital Voyage - Partnered with Signatures1",
        metaogdescription: "Discover how Signatures1 fueled Chennai Rice's digital journey. Our collaboration transformed their online presence, enhancing brand visibility.",
        metaogurl: "https://archive.signatures1.com/digital/chennai-rice",
        canonical: "https://archive.signatures1.com/digital/chennai-rice",
    },

    {
        id: 3,
        slug: "ata",
        bannerImage: [
            "/assets/images/digital/ata/atabanner.jpg",
        ],

        logoImage: "/assets/images/digital/ata/ata_logo_small.png",
        title: "EMPOWERING MINDS, ENRICHING FUTURES",
        description: "Logo Design, Branding, UI/UX, Visual Design, Social Media, Web Development & Hosting",
        logovideoText: "Capturing the essence of Education that is both dynamic in thought and meticulous in process, our design justifies both aspects through a versatile design that radiates vibrancy and serves as a ground for inspiration.",
        logovideoSrc: [
            {
                type: "image",
                source: "/assets/images/digital/ata/logo.jpg", // Image source
            },

        ],
        sliderImages: [
            {
                type: "video",
                source: "/assets/images/digital/ata/ata3.mp4",
            },
            {
                type: "video",
                source: "/assets/images/digital/ata/ata2.mp4",
            },
        ],

        textSec: "HERALDING THE NEW HORIZONS OF LEARNING THROUGH SEAMLESS DIGITAL NAVIGATION",
        scrollableContentImage: "/assets/images/digital/ata/ata.jpg",
        mobileImages1: [
            "/assets/images/digital/ata/m1.jpg",
        ],
        mobileImages2: [
            "/assets/images/digital/ata/m2.jpg",
        ],
        mobileImages3: [
            "/assets/images/digital/ata/m3.jpg",
        ],
        logovideoText2: "The client’s admiration for the website's flawless integration of academic and technological elements, commending its pivotal role in cultivating inquisitive intellects in young minds, serves as a testimony for the intensive thought behind the design that enriches the entirety of the educational experience.",
        bannerBottomImage: "/assets/images/digital/ata/atabottom.jpg",
        visitLink: "https://www.adwaithacademy.edu.in/",
        metaTitle: "Adwaith Thought Academy Digital Portfolio - Signatures1",
        metaDescription: "Amplify Adwaith's online presence with expert digital branding services from Signatures1. We create strategies that resonate and engage.",
        metaogtitle: "Adwaith Thought Academy Digital Portfolio - Signatures1",
        metaogdescription: "Amplify Adwaith's online presence with expert digital branding services from Signatures1. We create strategies that resonate and engage.",
        metaogurl: "https://www.archive.signatures1.com/digital/ata",
        canonical: "https://www.archive.signatures1.com/digital/ata",
    },

    {
        id: 4,
        slug: "sarvvam",
        bannerImage: [
            "/assets/images/digital/sarvvam/sarvvambanner.jpg",
        ],

        logoImage: "/assets/images/digital/sarvvam/sarvvam_logo_small.png",
        title: "ENVISIONING EXCELLENCE THROUGH INSPIRING INTERFACES",
        description: "UI/UX, Visual Design, SEO, Web Development & Hosting",
        logovideoText: "The website design encapsulates the school's commitment to holistic development, integrating comprehensive learning resources with an intuitive interface. Explore, engage, and evolve – all with a touch that's as innovative as it is immersive.",
        logovideoSrc: [
            {
                type: "image",
                source: "/assets/images/digital/sarvvam/logo.jpg", // Image source
            },

        ],
        sliderImages: [
            {
                type: "image",
                source: "/assets/images/digital/sarvvam/s1.jpg",
            },
            {
                type: "image",
                source: "/assets/images/digital/sarvvam/s2.jpg",
            },
        ],

        textSec: "WEAVING TOGETHER A WORLD OF RESOURCES WITH AN INTERFACE THAT'S AS INTUITIVE AS IT IS INSPIRING",
        scrollableContentImage: "/assets/images/digital/sarvvam/sarvvam.jpg",
        mobileImages1: [
            "/assets/images/digital/sarvvam/m1.jpg",
        ],
        mobileImages2: [
            "/assets/images/digital/sarvvam/m2.jpg",
        ],
        mobileImages3: [
            "/assets/images/digital/sarvvam/m3.jpg",
        ],
        logovideoText2: " The client applauds the website's seamless blend of academia and technology where the design is centered around you, amplifying your educational journey. With effortless navigation, it's like a personalized compass guiding you towards a more enriched learning experience.",
        bannerBottomImage: "/assets/images/digital/sarvvam/sarvvambottom.jpg",
        visitLink: "https://sarvvam.com/",
        metaTitle: "Sarvvam Digital Transformation - Developed by Signatures1",
        metaDescription: "Discover how Signatures1 drove Sarvam's digital transformation journey. Our expertise empowered change, amplifying their online presence and impact.",
        metaogtitle: "Sarvvam Digital Transformation - Developed by Signatures1",
        metaogdescription: "Discover how Signatures1 drove Sarvam's digital transformation journey. Our expertise empowered change, amplifying their online presence and impact.",
        metaogurl: "https://www.archive.signatures1.com/digital/sarvvam",
        canonical: "https://www.archive.signatures1.com/digital/sarvvam",
    },


];

export default digitalInnerPageData;
