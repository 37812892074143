/* eslint-disable no-undef */

import React from 'react';
import styled from 'styled-components';
import { theme } from '../../GlobalStyle';
import { Helmet } from 'react-helmet';

// ... rest of your component


const ZimsonCup = () => {
    return (

        <>
            <Helmet>
                <title>Zimson Cup Trophy - A Distinctive Identity Crafted by Signatures1 </title>
                <meta name="description" content="Explore how Signatures1's expertise gave Zimson Cup a unique design. Our creative journey ensured a brand identity that truly stands out." />

                <meta property="og:title" content="Zimson Cup Trophy - A Distinctive Identity Crafted by Signatures1" />
                <meta property="og:description" content="Explore how Signatures1's expertise gave Zimson Cup a unique design. Our creative journey ensured a brand identity that truly stands out." />
                <meta property="og:url" content="https://archive.signatures1.com/" />
                <meta property="og:site_name" content="Signatures1 Archive" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://archive.signatures1.com/branding/ZimsonCup" />
            </Helmet>

            <Wrapper>


                <div className="branding-container-lobein zimsoncup">
                    <video src={process.env.PUBLIC_URL + "/assets/videos/zimson-cup-branding.mp4"} autoplay loop muted playsinline className="video"></video>
                </div>


                <section className="svma-containers-flex">

                    <section className="work-second-sec">
                        <div className="inner-work-width">
                            <div>
                                <img
                                    src={process.env.PUBLIC_URL + "/assets/images/branding/zimson-cup/logo.png"} z
                                    alt="Zimson Logo - Signatures1"
                                    style={{ display: 'block', margin: '0 auto' }}
                                />


                            </div>
                            <h1>Lorem Ipsum is simply dummy text</h1>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                        </div>
                    </section>

                    <section className="lobein-brand-container lobein-brand-container-column ammis-sect zimson-rm">
                        <div className="brand-img">
                            <img src={process.env.PUBLIC_URL + "/assets/images/branding/zimson-cup/Group 2.png"} alt="Zimsoncup Image - Signatures1" />
                        </div>
                        <div className="brand-img1">
                            <h2>Lorem Ipsum is simply dummy text</h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                        </div>
                    </section>


                    <section className="sub-headings">
                        <div className="branding-container profile-hd">
                            <h2>TROPHY DESIGN</h2>
                        </div>
                    </section>

                    <section className="branding-container ammis-sect">
                        <img src={process.env.PUBLIC_URL + "/assets/images/branding/zimson-cup/Group 3.png"} alt="zimsoncup Image - Signatures1" />
                    </section>

                    <section className="branding-container ammis-sect">
                        <img src={process.env.PUBLIC_URL + "/assets/images/branding/zimson-cup/Group 4.png"} alt="zimsoncup Image - Signatures1" />
                    </section>

                    <div className="vivatta-flex">
                        <div className="vivatta-img">
                            <video src={process.env.PUBLIC_URL + "/assets/images/branding/zimson-cup/Group5.mp4"} autoplay loop muted playsinline className="video"></video>
                        </div>
                        <div className="vivatta-content">
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                        </div>
                    </div>

                    <section className="branding-container ammis-sect">
                        <img src={process.env.PUBLIC_URL + "/assets/images/branding/zimson-cup/Group 6.png"} alt="zimsoncup Image - Signatures1" />
                    </section>


                    <section className="branding-container ammis-sect">
                        <img src={process.env.PUBLIC_URL + "/assets/images/branding/zimson-cup/Group 7.png"} alt="zimsoncup Image - Signatures1" />
                    </section>

                    <section className="branding-video-flex">
                        <div className="zimsoncup-img1">
                            <video src={process.env.PUBLIC_URL + "/assets/images/branding/zimson-cup/Group8.mp4"} autoplay loop muted playsinline className="video"></video>
                        </div>
                        <div className="zimsoncup-img2">
                            <img src={process.env.PUBLIC_URL + "/assets/images/branding/zimson-cup/Group 9.png"} alt="" />
                        </div>
                        <div className="zimsoncup-img3">
                            <img src={process.env.PUBLIC_URL + "/assets/images/branding/zimson-cup/Group 10.png"} alt="" />
                        </div>
                    </section>

                    <section className="branding-video-flex extra-space">
                        <div className="zimsoncup-img4">
                            <img src={process.env.PUBLIC_URL + "/assets/images/branding/zimson-cup/Group 11.png"} alt="" />
                        </div>
                        <div className="zimsoncup-img5">
                            <img src={process.env.PUBLIC_URL + "/assets/images/branding/zimson-cup/Group 12.png"} alt="" />
                        </div>
                        <div className="zimsoncup-img6">
                            <img src={process.env.PUBLIC_URL + "/assets/images/branding/zimson-cup/Group 13.png"} alt="" />
                            <img src={process.env.PUBLIC_URL + "/assets/images/branding/zimson-cup/Group 14.png"} alt="" />
                        </div>
                    </section>
                </section>

                <a href="/" className='see-all-works-links--a'>
                    <div className="custom-container works-btn">
                        <a href="/">Archive</a>
                    </div>
                </a>

            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
background-image: url('../../assets/images/background-image.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top center;
        background-color: #000000bf;
        background-blend-mode: color;

.zimsoncup-img1{
    width: calc(22.1% - 2.66rem);
    line-height: 0;
}
.zimsoncup-img1 video {
    height: unset;
}

.zimsoncup-img2{
    width: calc(41.8% - 2.66rem);
    line-height: 0;
}

.zimsoncup-img3{
    width: calc(36% - 2.66rem);
    line-height: 0;
}
.branding-video-flex {
    display: flex;
    flex-direction: row;
    gap: 4rem;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    width: 100%;
}

.branding-video-flex img{
    width: 100%;
}

.zimsoncup-img4{
    width: calc(37% - 2.66rem);
    line-height: 0;
}

.zimsoncup-img5{
    width: calc(24.6% - 2.66rem);
    line-height: 0;
}

.zimsoncup-img5 img{
    height: 100%;
}

.zimsoncup-img1 img{
    height: 100%;
}

.zimsoncup-img4 img{
    height: 100%;
}

.zimsoncup-img6{
    width: calc(38.3% - 2.66rem);
    line-height: 0;
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.zimsoncup-img1 video{
    height: unset;
    width: 100%;
}

.zimsoncup{
    line-height: 0;
}
.vivatta-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 4rem;
}
.vivatta-img {
    width: calc(42.8% - 2rem);
    line-height: 0;
}
.vivatta-content {
    width: calc(57.2% - 2rem);
    padding-right: 3rem;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
p {
    font-size: 20px;
    color: #b7b7b7;
}
.extra-space{
    padding-bottom: 3rem;
}


@media (max-width: ${theme.breakpoints.medium}) {
    p {
    font-size: 18px;
}
    .zimsoncup-img1{
        width: calc(22.1% - 1.33rem);
    }
    
    .zimsoncup-img2{
        width: calc(41.8% - 1.33rem);
    }
    
    .zimsoncup-img3{
        width: calc(36% - 1.33rem);
    }
    .branding-video-flex {
    gap: 2rem;
}
    
    .branding-video-flex img{
        width: 100%;
    }
    
    .zimsoncup-img4{
        width: calc(36.5% - 1.33rem);
    }
    
    .zimsoncup-img5{
        width: calc(24.6% - 1.33rem);
    }
    
    .zimsoncup-img6{
        width: calc(37.6% - 1.33rem);
        gap: 2rem;
    }
    .svma-containers-flex {
        padding-bottom: 0;
        gap: 2rem;
    }
    .vivatta-flex {
    gap: 2rem;
}
.vivatta-img {
    width: calc(42.8% - 1rem);
}
.vivatta-content {
    width: calc(57.2% - 1rem);
    padding-right: 2rem;
}
  }


  @media (max-width: ${theme.breakpoints.small}) {
    .zimsoncup-img1{
        width: 100%;
    }
    
    .zimsoncup-img2{
        width: 100%;
    }
    
    .zimsoncup-img3{
       width: 100%;
    }
    
    .zimsoncup-img4{
       width: 100%;
    }
    
    .zimsoncup-img5{
       width: 100%;
    }
    
    .zimsoncup-img6{
       width: 100%;
    }
    .vivatta-img {
    width: 100%;
}
.vivatta-img {
    width: 100%;
}
.vivatta-content {
    width: 100%;
    padding: 0 3rem;
}

  }

`

export default ZimsonCup
