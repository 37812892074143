import React, { useEffect } from 'react';
import { autoScroll, autoScrollWithPause } from './scrollingFunctions';
import { useParams } from 'react-router-dom';
import digitalInnerPageData from '../DigitalInnerPageData';
import styled from 'styled-components';
import { theme } from "../GlobalStyle";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import { Autoplay, EffectFade } from 'swiper/modules';
import DigitalSlider from './DigitalSlider';
import { Helmet } from 'react-helmet';


const DigitalInnerPage = () => {
    const { slug } = useParams();
    const pageData = digitalInnerPageData.find((data) => data.slug === slug);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (pageData) {
            autoScroll();
        }
    }, [pageData]);

    useEffect(() => {
        if (pageData) {

            const scrollableContentOne = document.getElementById('scrollable-content-one');
            autoScrollWithPause(scrollableContentOne, 450, 1, 1500);

            const scrollableContentTwo = document.getElementById('scrollable-content-two');
            autoScrollWithPause(scrollableContentTwo, 510, 10, 1100);

            const scrollableContentThree = document.getElementById('scrollable-content-three');
            autoScrollWithPause(scrollableContentThree, 500, 15, 700);

        }
    }, [pageData]);

    if (!pageData) {
        // Handle the case where 'pageData' is not found
        return <div>Page not found</div>;
    }

    return (
        <>
            <Helmet>
                <title>{pageData.metaTitle}</title>  ‍
                <meta name="description" content={pageData.metaDescription} />
                <meta property="og:title" content={pageData.metaogtitle} />
                <meta property="og:description" content={pageData.metaogdescription} />
                <meta property="og:image" content="https://signatures1.com/images/signature-01.svg" />
                <meta property="og:url" content={pageData.metaogurl} />
                <link rel="canonical" href={pageData.canonical} />

            </Helmet>
            <Wrapper>
                <div className="work-banner">
                    <Swiper
                        className="mySwiper orthoSlide"
                        effect="fade"
                        loop={true}
                        fadeEffect={{ crossFade: true }}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay, EffectFade]}
                    >
                        {pageData.bannerImage.map((image, index) => (
                            <SwiperSlide key={index}>
                                <img src={process.env.PUBLIC_URL + image} alt="" />
                            </SwiperSlide>
                        ))}
                    </Swiper>


                </div>


                <section className="work-second-sec">
                    <div className="inner-work-width">
                        <div>
                            <img src={process.env.PUBLIC_URL + pageData.logoImage} className="lobein-logo-img" alt="Lobein Logo - Signatures1" style={{ display: 'block', margin: '0 auto' }} />
                        </div>
                        <h1>{pageData.title}</h1>
                        <p>{pageData.description}</p>
                    </div>
                </section>

                <section className="work-logovideo-flex">
                    <div className="work-logovideo-text">
                        <p>{pageData.logovideoText}</p>
                    </div>
                    <div className="work-logovideo-video lobein-25">
                        {pageData.logovideoSrc.map((item, index) => (
                            item.type === "image" ? (
                                <img key={index} src={process.env.PUBLIC_URL + item.source} alt="" />
                            ) : (
                                <video key={index} src={process.env.PUBLIC_URL + item.source} autoPlay loop muted playsInline />
                            )
                        ))}
                    </div>
                </section>

                {/* <section className="lobein-page">
                <div className="swiper mySwiper worksSlide">
                    <div className="swiper-wrapper">
                        {pageData.sliderImages.map((image, index) => (
                            <div className="swiper-slide" key={index}>
                                <img src={process.env.PUBLIC_URL + image} alt="" />
                            </div>
                        ))}
                    </div>
                </div>
            </section> */}
                <section className="lobein-page">
                    <Swiper
                        className="mySwiper orthoSlide"
                        effect="fade"
                        loop={true}
                        fadeEffect={{ crossFade: true }}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay, EffectFade]}
                    >
                        {pageData.sliderImages.map((item, index) => (
                            <SwiperSlide key={index}>
                                {item.type === "video" ? (
                                    <div class="video-container ata-two">
                                        <video src={process.env.PUBLIC_URL + item.source} autoPlay loop muted playsInline />
                                    </div>
                                ) : (
                                    <img src={process.env.PUBLIC_URL + item.source} alt="" />
                                )}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </section>

                {/* work--text-sec */}
                <section className="work--text-sec">
                    <p>{pageData.textSec}</p>
                </section>

                {/* border */}
                <section>
                    <div className="border">
                        <div className="desktop-url-header">
                            <img src={process.env.PUBLIC_URL + "/assets/images/desktopheader.svg"} alt="Lobein Website - Signatures1" />
                        </div>
                        <div className="scrollable-content" id="scrollableContent" style={{ scrollBehavior: 'smooth' }}>
                            <img id="scrollImg" src={pageData.scrollableContentImage} alt="Scrollable" />
                        </div>
                    </div>
                </section>

                {/* work-mobile-view-flex */}
                <section>
                    <div className="work-mobile-view-flex">
                        <div className="work-mobile-view-sec">
                            <div className="border-mobile">
                                <div className="scrollable-content-mobile" id='scrollable-content-one'>
                                    <img src={pageData.mobileImages1} alt="Scrollable" />
                                </div>

                            </div>
                        </div>
                        <div className="work-mobile-view-sec">
                            <div className="border-mobile">
                                <div className="scrollable-content-mobile" id='scrollable-content-two'>
                                    <img src={pageData.mobileImages2} alt="Scrollable" />
                                </div>

                            </div>
                        </div>
                        <div className="work-mobile-view-sec">
                            <div className="border-mobile">
                                <div className="scrollable-content-mobile" id='scrollable-content-three'>
                                    <img src={pageData.mobileImages3} alt="Scrollable" />
                                </div>

                            </div>
                        </div>
                    </div>
                </section>

                <section className="work-logovideo-flex">
                    <div className="work-logovideo-text-2">
                        <p>{pageData.logovideoText2}</p>
                    </div>
                </section>
                <section className="work-banner">
                    <img src={pageData.bannerBottomImage} alt="Lobein Website - Signatures1" />
                </section>
                <section className="work-visit-link">
                    <a href={pageData.visitLink} className="work-visit-link-sec" target="_blank" rel="noopener noreferrer">
                        <p>VISIT</p>
                        <img src={process.env.PUBLIC_URL + "/assets/images/launch.svg"} alt="Launch - Signatures1" />
                    </a>
                </section>

                {/* Include the DigitalSlider component here */}
                <DigitalSlider />

                <section
                    className="see-all-works sal-animate"
                    data-sr-id="14"
                    style={{
                        visibility: 'visible',
                        opacity: 1,
                        transform: 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)',
                        transition: 'opacity 1s ease-in-out 0.1s, transform 1s ease-in-out 0.1s',
                    }}
                >
                    <a href="/" className='see-all-works-links--a'>
                        <div className="custom-container works-btn">
                            <a href="/">Archive</a>
                        </div>
                    </a>
                </section>
            </Wrapper>
        </>
    );
}



const Wrapper = styled.div`
        background-image: url('../../assets/images/background-image.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top center;
        background-color: #000000bf;
        background-blend-mode: color;
   
  
  .work-banner {
    line-height: 0;
}

.work-banner img {
    width: 100%;
    line-height: 0;
}

.orthoSlide .swiper {
    width: 100%;
    height: 100%;
}
.video-container video {
    width: 100%;
    height: auto;
}

.orthoSlide .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.worksSlide .swiper {
    width: 100%;
    height: 100%;
}

.worksSlide .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.work-second-sec {
    width: 100%;
    background-color: #212121;
}

.work-logovideo-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.work-logovideo-flex1 {
    align-items: center;
    padding: 4rem 0;
}

.work-logovideo-text {
    width: 50%;
}

.work-logovideo-text p {
    color: #C8C7C7;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    width: 80%;
    margin: auto;
}



.work-logovideo-video {
    width: 50%;
    line-height: 0;
}
/* .lobein-25 {
    padding: 5rem 0;
    background-color: #000;
} */
.work-logovideo-video video {
    width: 100%;
}
.work-logovideo-video img {
    width: 100%;
}

.work--text-sec {
    width: 60%;
    margin: auto;
    padding: 7rem 0 7rem 0;
}

.work--text-sec p {
    text-align: center;
    font-size: 50px;
    color: #fff;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    font-family:  'PT Sans', sans-serif;
}
.desktop-url-header {
    width: 100%;
    margin: auto;
}

.desktop-url-header img {
    width: 100%;
    height: 100%;
}

.scrollable-content {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.scrollable-content::-webkit-scrollbar {
    display: none;
}

.scrollable-content img {
    width: 100%;
    display: block;
}


.work-logovideo-text-2 {
    width: 80%;
    margin: 0 auto 10rem auto;
}

.work-logovideo-text-2 p {
    color: #C8C7C7;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    width: 80%;
    margin: auto;
    text-align: center;
}

.work-mobile-view-flex {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 10rem auto;
}

.work-mobile-view-sec {
    width: 27%;
}
.border {
    width: 80%;
    margin: auto;
    height: 80vh;
    border: 2px solid #fff;
    overflow: hidden;
}

.border-mobile {
    width: 100%;
    margin: auto;
    height: 78vh;
    border: 2px solid #fff;
    overflow: hidden;
}

.scrollable-content-mobile {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.scrollable-content-mobile img {
    width: 100%;
    display: block;
}

.scrollable-content-mobile::-webkit-scrollbar {
    display: none;
}

.work-visit-link {
    background: #242424;
}

.work-visit-link-sec {
    width: max-content;
    margin: auto;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    text-decoration: none;
    padding: 10rem 0;
    align-items: baseline;
    justify-content: center;
}
.work-visit-link-sec p:nth-child(1) {
    color: #A9A9A9;
    font-size: 20px;
    font-weight: 400;
    line-height: 0;
    text-transform: uppercase;
    text-align: left;
    font-family: 'PT Sans', sans-serif;
}

.work-visit-link-sec img {
    width: 26px;
    transition: all .5s ease;
}
.video-container video {
    width: 100%;
    height: auto;
}

.scroll-bounce {
    animation: bounce 0.5s infinite;
    /* Adjust animation duration as needed */
}
@keyframes bounce {

0%,
100% {
    transform: translateY(0);
}

50% {
    transform: translateY(-10px);
}
}

@media (max-width: ${theme.breakpoints.laptop}) {
    .work--text-sec {
        padding: 4rem 0 4rem 0;
    }
    .work--text-sec p {
        font-size: 34px;
    }
    .work-mobile-view-flex {
        margin: 4rem auto;
    }
    .work-mobile-view-sec {
        width: 30%;
    }
    .work-logovideo-text-2 {
        margin: 0 auto 4rem auto;
    }

  }

  @media (max-width: ${theme.breakpoints.extralarge}) {
    
    .inner-work-width {
        gap: 2rem;
        padding: 4rem 0;
    }
  }

  @media (max-width: ${theme.breakpoints.large}) {
    .work--text-sec p {
        font-size: 32px;
    }
    .border-mobile {
        height: 40vh;
    }
    .work-logovideo-text-2 p {
        font-size: 22px;
    }

  }

  @media (max-width: ${theme.breakpoints.tab}) {
    .work-logovideo-text p {
        font-size: 16px;
    }
    .work-logovideo-text-2 p {
        font-size: 18px;
        width: 100%;
        text-align: left;
    }
    .swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: 0!important;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: 0 !important;
}
  }

  @media (max-width: ${theme.breakpoints.medium}) {
    .work--text-sec p {
        font-size: 30px;
    }
    .work-mobile-view-sec {
        width: 32%;
    }
    .work-visit-link-sec {
        width: 80%;
        padding: 5rem 0;
    }
  }


  @media (max-width: ${theme.breakpoints.small}) {
    .inner-work-width {
        width: 100%;
    }
    .work-logovideo-flex {
        flex-direction: column;
        padding: 2rem 0 0;
    }
    .work--text-sec p {
        font-size: 22px;
    }
    .work-mobile-view-flex{
        margin: 2rem auto;
    }
    .work-logovideo-text {
        width:100%;
    }
    .work-logovideo-video img {
        width: 100%;
    }
    
.work-logovideo-video {
    width: 100%;
    margin: 2rem 0;
}
.work-logovideo-text p {
    width: 80%;
}
.work--text-sec {
    width: 80%;
    margin: auto;
    padding: 3rem 0;
}
.border-mobile {
    height: 32vh;
}
.work-logovideo-text-2 {
    width: 80%;
    margin: 0 0 4rem 0;
}
.work-visit-link-sec {
    padding: 3rem 0;
}
.swiper-button-next:after, .swiper-button-prev:after {
    font-size: 22px!important;
    font-weight: 200!important;
}
.works-btn {
    padding: 1rem 1rem;
}
  }
`
export default DigitalInnerPage;