export function autoScroll() {
  const scrollContainerBig = document.getElementById("scrollableContent");
  let scrollingDown = true;
  let scrollInterval;

  function scroll() {
    const scrollAmount = scrollingDown ? 600 : -600; // Adjust the scrolling speed
    scrollContainerBig.scrollTop += scrollAmount;

    if (
      scrollContainerBig.scrollTop + 1 >=
      scrollContainerBig.scrollHeight - scrollContainerBig.clientHeight
    ) {
      scrollContainerBig.scrollTop = 0;
    }
  }

  scrollContainerBig.addEventListener("mouseover", () => {
    scroll(); // Start scrolling immediately on hover
    scrollInterval = setInterval(scroll, 1500); // Scroll every 1.5 seconds
  });

  scrollContainerBig.addEventListener("mouseout", () => {
    clearInterval(scrollInterval);
  });
}

export function autoScrollWithPause(
  scrollContainer,
  scrollDistance,
  scrollSpeed,
  pauseDuration
) {
  console.log("autoScrollWithPause function called");
  let totalScrolled = 0;
  let scrollInterval;
  let isBouncing = false;

  function autoScroll() {
    const scrollAmount = 7; // Increase scroll amount for smoother animation
    scrollContainer.scrollTop += scrollAmount;
    totalScrolled += scrollAmount;

    if (
      scrollContainer.scrollTop + 1 >=
      scrollContainer.scrollHeight - scrollContainer.clientHeight
    ) {
      scrollContainer.scrollTop = 0; // Reset scroll position to the top
      clearInterval(scrollInterval);
      if (!isBouncing) {
        isBouncing = true;
        scrollContainer.classList.add("scroll-bounce"); // Add bounce animation class
        setTimeout(() => {
          scrollContainer.classList.remove("scroll-bounce"); // Remove bounce animation class
          isBouncing = false;
          // Pause auto-scrolling for the specified duration
          clearInterval(scrollInterval);
          setTimeout(() => {
            scrollInterval = setInterval(autoScroll, scrollSpeed);
          }, pauseDuration);
        }, 500); // Bounce animation duration
      }
    } else if (totalScrolled >= scrollDistance) {
      totalScrolled = 0;
      clearInterval(scrollInterval);
      if (!isBouncing) {
        isBouncing = true;
        scrollContainer.classList.add("scroll-bounce"); // Add bounce animation class
        setTimeout(() => {
          scrollContainer.classList.remove("scroll-bounce"); // Remove bounce animation class
          isBouncing = false;
          // Pause auto-scrolling for the specified duration
          clearInterval(scrollInterval);
          setTimeout(() => {
            scrollInterval = setInterval(autoScroll, scrollSpeed);
          }, pauseDuration);
        }, 500); // Bounce animation duration
      }
    }
  }

  scrollInterval = setInterval(autoScroll, scrollSpeed);
}
