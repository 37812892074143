import React, { useState, useRef } from "react";
import styled from "styled-components";
import { theme } from "../GlobalStyle";
import axios from 'axios';

const Footer = () => {


  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const formRef = useRef(null);


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation logic
    let errors = {};

    if (!formData.name.trim()) {
      errors.name = "Name is required";
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      errors.email = "Invalid email address";
    }

    if (!/^\+?[1-9]\d{1,14}$/.test(formData.phone)) {
      errors.phone = "Invalid phone number";
    }

    if (!formData.message.trim()) {
      errors.message = "Message is required";
    }

    setFormErrors(errors);

    // If there are no errors, submit the form
    if (Object.keys(errors).length === 0) {
      try {
        const response = await axios.post('https://back.signatures1.com/api/contact', formData);
        // console.log('Response from server:', response.data);
        // You can handle success here (e.g., show a success message)
        setSuccessMessage("Form Submitted Successfully! Thank you for contacting us. We will get back to you soon"); // Assuming your server sends a 'message' property in the response

        document.querySelector('.response_message_div').style.display = 'block';
        // Hide response message div after 10 seconds
        setTimeout(() => {
          document.querySelector('.response_message_div').style.display = 'none';
        }, 5000);

        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });

        setErrorMessage(""); // Reset error message in case there was a previous error

      } catch (error) {
        console.error('Error sending request:', error);
        // You can handle errors here (e.g., show an error message)
        setErrorMessage("An error occurred while sending the request");
        setSuccessMessage(""); // Reset success message in case there was a previous success message
      }
    }
  };

  return (
    <FooterWrapper id="footer-cursor">
      <div className="custom-container footer-sec">
        <h2>Let’s Take Things Forward</h2>
        <div className="form-container">
          {/* Static form */}


          <form id="ContactFormFooter" ref={formRef} >
            <div className="footer-form-flex">
              <div className="footer-form-inputs">
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}

                />
                {formErrors.name && <div className="error-message">{formErrors.name}</div>}

                <br />

                <input
                  type="email"
                  name="email"
                  placeholder="name@mail.com"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}

                />
                {formErrors.email && <div className="error-message">{formErrors.email}</div>}

                <br />

              </div>
              <div className="footer-form-inputs">
                <input
                  type="tel"
                  name="phone"
                  placeholder="+91"
                  maxLength="10"
                  value={formData.phone}
                  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}

                />
                {formErrors.phone && <div className="error-message">{formErrors.phone}</div>}

                <br />

                <textarea
                  rows="1"
                  name="message"
                  placeholder="Message"
                  value={formData.message}
                  onChange={(e) => setFormData({ ...formData, message: e.target.value })}

                ></textarea>
                {formErrors.message && <div className="error-message">{formErrors.message}</div>}

                <br />

                <div className="submit-btn1">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/arrow-send.svg"}
                    alt="Arrow Icon - Signatures1"
                    className="submit-arrow"
                  />
                  <input onClick={handleSubmit} type="button" value="send" />
                </div>
              </div>
            </div>
          </form>
          {/* Response message and error message elements */}
          <div className="response_message_div">
            {successMessage && <img src={process.env.PUBLIC_URL + "/assets/images/tick.svg"} alt="Success icon" width="40px" height="40px" />}
            <p className="response_message_p">{successMessage}</p>
            {errorMessage && <div id="error_message">{errorMessage}</div>}
          </div>
        </div>
      </div>
      <section className="custom-container coprights-sec">
        <div className="coprights">
          <div className="copyrights-flex">
            <div className="coprights-text">
              <p>&#169; 2023 Signatures1&nbsp; | &nbsp;<a href="https://signatures1.com/sitemap.xml" class="sitemap-link">Sitemap</a></p>
            </div>
            <div className="copyrights-socialicons-flex">
              <a
                aria-label="Signatures1 facebook"
                href="https://www.facebook.com/signatures1designsandcommunications/"
                target="_blank"
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/facebook.svg"}
                  alt="Facebook Icon - Signatures1"
                />
              </a>
              <a
                aria-label="Signatures1 Instagram"
                href="https://www.instagram.com/signatures1.com_/"
                target="_blank"
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/instagram.svg"}
                  alt="Instagram Icon - Signatures1"
                />
              </a>
              <a
                aria-label="Signatures1 LinkedIn"
                href="https://in.linkedin.com/company/signatures1designsandcommunications"
                target="_blank"
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/linkedin.svg"}
                  alt="LinkedIn Icon - Signatures1"
                />
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* Cookie Consent Banner */}
      {/* <div id="cookie-consent" className="cookie-consent">
                <p className="cookie-text">This website uses cookies to offer you a better browsing experience. By using our website, You agree to the use of cookies.</p>
                <button id="accept-cookies-btn">Accept</button>
                <button id="decline-cookies-btn">Decline</button>
            </div> */}
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  padding: 5rem 0 0;

  .footer-sec h2 {
    font-size: 50px;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  .error-message {
    color:red;
  }
  .footer-form-flex {
    display: flex;
    justify-content: space-between;
  }

  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #000 inset !important;
    -webkit-text-fill-color: #fff !important;
  }

  .footer-form-inputs {
    width: 46%;
    position: relative;
  }

  .footer-form-inputs input {
    width: 100%;
    background: transparent;
    border: unset;
    box-shadow: unset;
    outline: unset;
    color: #fff;
    border-bottom: 1px solid #333;
    padding: 2rem 0 0.2rem;
    margin: 3rem 0 0.3rem 0;
    font-size: 40px;
    font-weight: 300;
    font-family: "Barlow", sans-serif;
    position: relative!important;
    z-index: 9;
  }

  .footer-form-inputs input::placeholder {
    font-size: 40px;
    font-weight: normal;
    color: #535353;
    font-family: "Barlow", sans-serif;
  }

  .footer-form-inputs textarea {
    width: calc(100% - 56px);
    background: transparent;
    border: unset;
    box-shadow: unset;
    outline: unset;
    color: #fff;
    border-bottom: 1px solid #333;
    padding: 2rem 0 0.2rem 0;
    margin: 3rem 0 0.3rem 0;
    position: relative;
    font-size: 40px;
    font-family: "Barlow", sans-serif;
    font-weight: 300;
    resize: none;
  }

  .footer-form-inputs textarea::placeholder {
    font-size: 40px;
    font-weight: normal;
    color: #535353;
    font-family: "Barlow", sans-serif;
  }

  .footer-form-inputs input[type="button"] {
    padding: 0;
    border: unset;
  }

  .submit-btn1 {
    width: 56px;
    aspect-ratio: 1/1;
    position: absolute;
    bottom: 10%;
    right: 0;
  }

  .submit-btn1 img {
    width: 52px;
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }

  .submit-btn1 input {
    width: 52px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    z-index: 3;
    cursor: pointer;
  }

  .coprights-sec {
    background-color: ${({ theme }) => theme.colors.primaryColor};
  }

  .coprights {
    padding: 50px 0;
  }

  .copyrights-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .coprights-text p {
    font-size: 18px;
    color: #9C9C9C;
    font-weight: 400;
  }

  .copyrights-socialicons-flex {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }

  .copyrights-socialicons-flex img {
    width: 100%;
  }
  .sitemap-link {
    text-decoration: none;
    color: #9C9C9C;
}

  @media (max-width: ${theme.breakpoints.laptop}) {
    
    .footer-sec h2 {
      font-size: 34px;
    }
    .footer-form-inputs input::placeholder {
      font-size: 22px !important;
    }

    .footer-form-inputs textarea::placeholder{
      font-size: 22px !important;
    }
  }


  @media (max-width: ${theme.breakpoints.medium}) {
    .footer-form-inputs {
        width: 100%;
    }

    .footer-form-flex {
        flex-direction: column;
    }

    .footer-form-inputs input {
        margin: 1rem 0;
        padding: 1rem 0 0.2rem;
        font-size: 20px;
    }
   

    .footer-form-inputs  textarea {
     margin: 1rem 0;
        padding: 1rem 0 0.2rem;
        font-size: 20px;
}

  }


  @media (max-width: ${theme.breakpoints.small}) {
    .footer-sec h2 {
    font-size: 24px;
}

    .footer-form input::placeholder {
      font-size: 16px;
    }

    .footer-form textarea::placeholder {
      font-size: 16px;
    }
  }


  .response_message_div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    display: none;
    z-index: 10;


}

.response_message_div p {
    top: 50%;
    left: 50%;
    position: fixed;
    color: #fff;
    text-align: center;
    transform: translate(-50%, -50%);
    font-weight: 500;
    font-size: 1.4rem;
}


.response_message_div img {
    position: fixed;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -20%);
}

`;

export default Footer;
