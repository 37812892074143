import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation'; // Import Swiper navigation styles
import { Autoplay, Navigation } from 'swiper/modules'; // Remove the duplicate import


const digitalDetailsSlide = [
  {
    type: "image",
    src: "/assets/images/ortho-one-logo.jpg",
    alt: "LMW client logo - Signatures1",
    link: "/digital/ortho-one",
  },
  {
    type: "image",
    src: "/assets/images/chennai-rice-logo.jpg",
    alt: "LMW client logo - Signatures1",
    link: "/digital/chennai-rice",
  },
  {
    type: "video",
    src: "/assets/videos/digital/ata-logo.mp4",
    link: "/digital/ata",
  },
  {
    type: "video",
    src: "/assets/videos/digital/sarvvam-logo.mp4",
    link: "/digital/sarvvam",
  },
];

const DigitalSlider = () => {
  return (
    <Wrapper>

      <div className="navigation-container">

      </div>
      <Swiper
        className="mySwiper viewWorks"
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={{
          prevEl: '.custom-prev-button',
          nextEl: '.custom-next-button',
        }}

        modules={[Autoplay, Navigation]}
        slidesPerView={1}
        spaceBetween={20}
        breakpoints={{
          576: {
            slidesPerView: 2,
          },
        }}
      >
        {digitalDetailsSlide.map((item, index) => (
          <SwiperSlide key={index}>
            {item.type === 'image' ? (
              <a href={item.link} className="viewWorks-flex-sec">
                <img src={item.src} alt={item.alt} />
              </a>
            ) : (
              <a href={item.link} className="viewWorks-flex-sec">
                <video src={item.src} autoPlay loop muted playsInline />
              </a>
            )}
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Custom position for the Swiper navigation arrows */}
      <div className="custom-prev-button swiper-button-prev"></div>
      <div className="custom-next-button swiper-button-next"></div>

    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* Your styles here */

  position: relative;

  .viewWorks {
    width: 80%;
    margin: 6rem auto;
    position: relative;
  }

  .viewWorks-flex-sec {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-decoration: none;
  }

  .viewWorks-flex-sec img {
    width: 100%;
  }

  .viewWorks-flex-sec video {
    width: 100%;
    height: auto;
  }

  .viewworksSec {
    position: relative;
  }

  #viewWorks {
    position: relative !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    color: white;
    font-size: 22px!important;
    font-weight: 200!important;
  }

  .swiper-button-prev {
    left: 6%; 
  }

  .swiper-button-next {
    right: 6%; 
  }
`;

export default DigitalSlider;
