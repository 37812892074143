import React, { useEffect } from 'react';
import Tab from "./components/Tab";
import styled from "styled-components";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Wrapper>
        <Tab />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  background-image: url("../../assets/images/background-image.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
  background-color: #000000bf;
  background-blend-mode: color;
`;

export default Home;
