import { createGlobalStyle } from "styled-components";

// Define your theme variables
export const theme = {
    colors: {
        primaryColor: "#000000",
        secondaryColor: "#E1251B",
        white: "#ffffff",
        blackish: "#161616",
        text: "#9C9C9C",
    },
    breakpoints: {
        extralaptop: "1536px",
        laptop: "1440px",
        smalllaptop: "1366px",
        extralarge: "1280px",
        large: "1024px",
        tab: "900px",
        medium: "768px",
        small: "576px",
    },
};

// Define your global styles
const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    html {
  scroll-behavior: smooth;
}

    
    body {
        font-family: 'Barlow', sans-serif;
        background-color: ${({ theme }) => theme.colors.primaryColor};
    }
    // main {
    //     background-image: url('../public/assets/images/background-image.svg');
    //     background-repeat: no-repeat;
    //     background-size: contain;
    //     background-position: top center;
    //     background-color: #000000bf;
    //     background-blend-mode: color;
    // }
    
    
    h1 {
        font-family: 'PT Sans', sans-serif;
        color:  ${({ theme }) => theme.colors.white};
    }
    
    h2 {
        font-family: 'PT Sans', sans-serif;
        color:  ${({ theme }) => theme.colors.white};
    }
    
    h3 {
        font-family: 'PT Sans', sans-serif;
        color:  ${({ theme }) => theme.colors.white};
    }
    p {
        font-family: 'Barlow', sans-serif;
        color:  ${({ theme }) => theme.colors.text};
    }
    .custom-container {
        width: 80%;
        margin: auto;
    }

    .paddings {
        padding: 5rem 0;
    }
    .header {
    position: relative;
    padding: 30px 0;
}

    .common-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .inner-text-width {
        width: 70%;
    }

    .inner-text-width h2 {
        font-size: 50px;
        font-weight: 400;
        margin-bottom: 1.5rem;
    }
    .swiper-slide img {
    width: 100%;
}

.inner-work-width {
    width: 80%;
    margin: auto;
    padding: 10rem 0;
    display: flex;
    flex-direction: column;
    gap: 5rem;
    align-items: center;
}
.inner-work-width img {
    /*width: 100%;*/
    margin: auto;
    border-bottom: 1px solid #585858;
}
.inner-work-width img:nth-child(2) {
    width:100%
}
.inner-work-width h1 {
    font-size: 50px;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    font-family: 'PT Sans', sans-serif;
    width: 70%;
}
.inner-work-width p {
    width: 46%;
    margin: auto;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    color: #C8C7C7;
    text-align: center;
    text-transform: capitalize;
}

.lobein-brand-container {
    display: flex;
    flex-direction: row;
    gap: 4rem;
    width: 100%;
    /* padding: 0 0 4rem; */
    justify-content: space-between;
    align-items: center;
}

.lobein-brand-container .brand-img {
    width: calc(50% - 2rem);
}
.svma-containers-flex {
    display: flex;
    flex-direction: column;
    gap: 4rem;
}
.brand-img {
    position: relative;
    z-index: 9;
}
.brand-img1 h2 {
    font-size: 50px;
    font-weight: 500;
    text-transform: uppercase;
}
.brand-img1 p {
    max-width: 650px;
}
.brand-img1 {
    width: calc(50% - 2rem);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 2rem 0 0;
}
.profile-hd {
    padding: 4rem 0;
}
.branding-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}
.branding-container h2 {
    font-size: 50px;
    font-weight: 400;
    text-transform: uppercase;
}
.branding-container img {
    width: 100%;
}
.brand-img img {
    width: 100%;
}
.branding-container-lobein video {
    width: 100%;
    height: 100%;
}

.work-second-sec {
    width: 100%;
    background-color: #212121;
}
.video {
    width: 100%;
   
}


.see-all-works-links--a{
    text-decoration: none;
}
.works-btn {
    text-align: center;
    border: 1px solid #fff;
    padding: 2rem 1rem;
    position: relative;
    margin: 2rem auto;
    cursor: pointer;
}
.works-btn::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    background-color: #FF0000;
    transition: all .4s ease;
}
.works-btn:hover::after {
    height: 100%;
}

.works-btn a {
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    color: #fff;
    position: relative;
    z-index: 9;
}


@media (max-width: ${theme.breakpoints.extralaptop}) {
    .inner-work-width {
        gap: 2rem;
    } 
    .brand-img1{
        padding: 0 4rem 0 0;
    } 
    .menu-button2>p {
   
    font-size: 18px;
   
}
}


@media (max-width: ${theme.breakpoints.laptop}) {
    .inner-work-width {
    padding: 5rem 0 5rem 0;
}
.inner-work-width h1 {
        font-size: 34px;
    }
    .branding-container h2 {
    font-size: 34px;
}
.brand-img1 h2{
        font-size: 34px;
    }
    .footer-sec h2 {
    font-size: 34px;
}
}


@media (max-width: ${theme.breakpoints.smalllaptop}) {
    .brand-img1 h2 {
    font-size: 30px;
}
.brand-img1 h2 {
    font-size: 30px;
}
    .inner-work-width h1 {
        font-size: 30px;
    }
    .branding-container h2 {
    font-size: 30px;
}
}

@media (max-width: ${theme.breakpoints.extralarge}) {
 
}

@media (max-width: ${theme.breakpoints.tab}) {
    .brand-img1 p {
        font-size: 18px;
    }
}


@media (max-width: ${theme.breakpoints.large}) {
    p {
        font-size: 20px;
    }
    
    .inner-work-width {
        width: 80%;
    }
    .inner-work-width img {
        width: 100%;
    }
    .inner-work-width h1 {
        font-size: 24px;
    }
    .inner-work-width p {
        width: 86%;
    }
    .lobein-brand-container .brand-img {
        width: calc(50% - 1rem);
        line-height: 0;
    }
    .profile-hd {
        padding: 4rem 0;
    }
    .video {
        height: 50vh;
    }

}

@media (max-width: ${theme.breakpoints.medium}) {
    p {
    font-size: 18px;
}
    .paddings {
        padding: 3rem 0 0 0;
    }
     
.brand-img img {
    width: 100%;
}
.brand-img1 {
    padding: 0;
    width: 80%;
}
.brand-img1 h2{
    font-size: 24px;
}
.lobein-brand-container {
    flex-direction: column;
}
.lobein-brand-container .brand-img {
    width: 100%;
}
.branding-container h2 {
    font-size: 24px;
    padding-top: 0;
}

}

@media (max-width: ${theme.breakpoints.small}) {
    
    .work-logovideo-flex {
        flex-direction: column;
        padding: 2rem 0 0;
    }
    .lobein-brand-container-column {
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2rem;
}

.lobein-brand-container .brand-img {
    width: 100%;
}
#custom-content-1 .video-section .video {
    height: 40vh;
}
.works-btn{
    padding: 1rem;
}
}

`;

export default GlobalStyle;
