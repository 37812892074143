import React, { useState } from "react";
import styled from "styled-components";
import { useActiveTab } from './ActiveTabContext'; // Add this import
import Branding from "./Branding";
import Digital from "./Digital";
import { theme } from "../GlobalStyle";


function Tab() {
    const { activeTab, setActiveTab } = useActiveTab(); // Use the active tab context
    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };
    return (
        <TabWrapper>
            <div className="custom-container custom-tabs-container">
                {/* Branding tab */}
                <div
                    className={`custom-tab ${activeTab === 1 ? "custom-active" : ""}`}
                    onClick={() => handleTabClick(1)}
                >
                    <img
                        src={process.env.PUBLIC_URL + "/assets/images/branding.png"}
                        alt=""
                    />
                    <h2>Branding</h2>
                </div>
                {/* Digital tab */}
                <div
                    className={`custom-tab ${activeTab === 2 ? "custom-active" : ""}`}
                    onClick={() => handleTabClick(2)}
                >
                    <img
                        src={process.env.PUBLIC_URL + "/assets/images/digital.png"}
                        alt=""
                    />
                    <h2>Digital</h2>
                </div>
            </div>
            <div className="custom-tab-content">
                {/* Render the active tab content */}
                {activeTab === 1 && <Branding />}
                {activeTab === 2 && <Digital />}
            </div>
        </TabWrapper>
    );
}


const TabWrapper = styled.section`
    .custom-tabs-container {
    display: flex;
    overflow: hidden;
    position: relative;
    justify-content: flex-start;
    gap: 5rem;
    padding-bottom: 2rem;
    margin-top: 2rem;
}

.custom-tab {
    position: relative;
    font-size: 50px;
    font-weight: 400;
    line-height: normal;
    color: #646464;
    cursor: pointer;
    transform: scale(0.6);
    transition: all 0.3s ease;
    width: 150px;
}
.custom-tab img {
    width: 100%;
}
.custom-tab.custom-active {
    transform: scale(1);
}

.custom-tab-border.slide-transition {
    transition: transform 0.3s ease-in-out;
}

.custom-tab h2 {
    color: ${({ theme }) => theme.colors.white};
    font-size: 36px;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
}
@media (max-width: ${theme.breakpoints.small}) {
    .custom-tab h2 {
        font-size: 28px;
    } 
}

@media (max-width: ${theme.breakpoints.small}) {
       
    .custom-tabs-container {
        gap: 3rem;
    }
  }

`;

export default Tab;
