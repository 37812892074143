import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../GlobalStyle";
// import digitalInnerPageData from "../DigitalInnerPageData";

const digitalDetails = [
  {
    type: "image",
    src: "/assets/images/ortho-one-logo.jpg",
    alt: "LMW client logo - Signatures1",
    link: "/portfolio/digital/ortho-one",
  },
  {
    type: "image",
    src: "/assets/images/chennai-rice-logo.jpg",
    alt: "LMW client logo - Signatures1",
    link: "/portfolio/digital/chennai-rice",
  },
  {
    type: "video",

    src: "/assets/videos/digital/ata-logo.mp4",
    link: "/portfolio/digital/ata",
  },
  {
    type: "video",
    src: "/assets/videos/digital/sarvvam-logo.mp4",
    link: "/portfolio/digital/sarvvam",
  },
];

const Digital = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className="custom-content" id="custom-content-2">
      <DigitalListFlex>
        {digitalDetails.map((item, index) => (
          <Link to={item.link} className="digital-list-flex-sec" key={index}>
            {item.type === "image" ? (
              <img src={process.env.PUBLIC_URL + item.src} alt={item.alt} />
            ) : item.type === "video" ? (
              <video
                src={process.env.PUBLIC_URL + item.src}
                autoPlay
                loop
                muted
                playsInline
                preload="metadata"
              ></video>
            ) : null}
          </Link>
        ))}
      </DigitalListFlex>
    </div>
  );
};

const DigitalListFlex = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 4rem 0;
  padding: 8rem 0 5rem 0;

  .digital-list-flex-sec {
    width: 48%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-decoration: none;
  }

  .digital-list-flex-sec video {
    width: 100%;
    height: auto;
  }

  .digital-list-flex-sec img {
    width: 100%;
  }

  .digital-list-flex-sec p {
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
  }

  @media (max-width: ${theme.breakpoints.small}) {
    div{   
    display: flex;
    flex-direction: column;
  }
   
.digital-list-flex-sec {
    width: 100%;
}
  }


`;

export default Digital;
