import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import GlobalStyle, { theme } from "./GlobalStyle";
import { Helmet } from 'react-helmet';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./Home";
import Digital from "./components/Digital";
import DigitalInnerPage from "./components/DigitalInnerPage";
// import digitalInnerPageData from './DigitalInnerPageData';
import Branding from "./components/Branding";
import ZimsonCup from "./components/branding/ZimsonCup";
import { ActiveTabProvider } from './components/ActiveTabContext'; // Add this import



const App = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ActiveTabProvider>
        <div>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <BrowserRouter>
              <Header />
              <Helmet>
                <title>Signatures1 Portfolio Archive - Showcasing Our Creative Works</title>
                <meta name="description" content="Explore a diverse collection of portfolios in our comprehensive archive page, showcasing the best of creative talent - Signatures1" />
                <meta property="og:title" content="Signatures1 Portfolio Archive - Showcasing Our Creative Works" />
                <meta property="og:description" content="Explore a diverse collection of portfolios in our comprehensive archive page, showcasing the best of creative talent - Signatures1" />
                <meta property="og:url" content="https://archive.signatures1.com/" />
                <meta property="og:image" content="https://signatures1.com/images/signature-01.svg" />
                <meta property="og:site_name" content="Signatures1 Archive" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://archive.signatures1.com/" />
              </Helmet>

              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/digital" element={<Digital />} />
                <Route path="/digital-inner-page" element={<DigitalInnerPage />} />
                <Route path="/branding" element={<Branding />} />
                <Route path="/portfolio/branding/zimsoncup" element={<ZimsonCup />} />
                <Route path="/portfolio/digital/:slug" element={<DigitalInnerPage />} />
              </Routes>

              <Footer />
            </BrowserRouter>
          </ThemeProvider>
        </div>
      </ActiveTabProvider>
    </>
  );
};

export default App;
