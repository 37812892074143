import React from "react";
import styled, { keyframes } from "styled-components";
import { theme } from "../GlobalStyle";
import { Link, NavLink } from "react-router-dom";


const example1 = keyframes`
0% {
    left: 0px;
    top: 0px;
}

25% {
    left: 25px;
    top: 0px;
}

50% {
    left: 25px;
    top: 25px;
}

75% {
    left: 0px;
    top: 25px;
}

100% {
    left: 0px;
    top: 0px;
}
`;

const example2 = keyframes`
0% {
    left: 25px;
    top: 0px;
}

25% {
    left: 25px;
    top: 25px;
}

50% {
    left: 0px;
    top: 25px;
}

75% {
    left: 0px;
    top: 0px;
}

100% {
    left: 25px;
    top: 0px;
}
`;

const example3 = keyframes`
0% {
    left: 25px;
    top: 25px;
}

25% {
    left: 0px;
    top: 25px;
}

50% {
    left: 0px;
    top: 0px;
}

75% {
    left: 25px;
    top: 0px;
}

100% {
    left: 25px;
    top: 25px;
} 

`;

const example4 = keyframes`
0% {
    left: 0px;
    top: 25px;
}

25% {
    left: 0px;
    top: 0px;
}

50% {
    left: 25px;
    top: 0px;
}

75% {
    left: 25px;
    top: 25px;
}

100% {
    left: 0px;
    top: 25px;
}

`;

const Header = () => {
    const toggleMenu = () => {
        const viewport = window.innerWidth;

        var menuButtonSection = document.getElementById("menuButtonSection");

        // Check if the menu-button section is visible or not
        let isVisible =
            window.getComputedStyle(menuButtonSection).getPropertyValue("display") ===
            "block";

        if (isVisible) {
            // If the menu-button section is visible, hide it by sliding it to the top
            menuButtonSection.style.transform = "translateY(-100%)";
            menuButtonSection.style.visibility = "hidden";

            setTimeout(function () {
                menuButtonSection.style.display = "none";
            }, 300); // Match the CSS transition time (0.3s) for smooth sliding effect
        } else {
            // If the menu-button section is hidden, show it by sliding it to its place
            menuButtonSection.style.display = "block";
            menuButtonSection.style.visibility = "visible";

            setTimeout(function () {
                menuButtonSection.style.transform = "translateY(0%)";
            }, 10); // A small delay to ensure the display property is updated before animating

            if (viewport < 769) {
                menuButtonSection.style.backgroundColor = "#00";
                document.querySelector("header").style.backgroundColor = "#000";
            } else {
                menuButtonSection.style.backgroundColor = "#000";
                document.querySelector("header").style.backgroundColor = "#000";
            }
        }
    };

    return (
        <HeaderWrapper>
            <div className="header">
                <div className="custom-container common-flex hd-index">
                    <div className="brand-img changing-gif">
                        <a href="https://signatures1.com" target="_blank">


                            <img
                                src={
                                    process.env.PUBLIC_URL + "/assets/images/logo-25-years.gif"
                                }
                                alt="Signatures1 Icon - Signatures1"
                                className="logo--signatures-vehicle"
                            />
                        </a>
                    </div>
                    <div className="brand-img">
                        <a href="https://signatures1.com" target="_blank">
                            <img
                                src={
                                    process.env.PUBLIC_URL + "/assets/images/signatures1-logo.svg"
                                }
                                alt="Signatures1 Icon - Signatures"
                                className="logo--signatures"
                            />
                        </a>
                    </div>
                    <div className="rotate-menu-container" onClick={toggleMenu}>
                        <div className="menu1"></div>
                        <div className="menu2"></div>
                        <div className="menu3"></div>
                        <div className="menu4"></div>
                    </div>
                </div>
                <div id="menuButtonSection">
                    <div className="menu-button">
                        <a href="https://signatures1.com/business-solutions" target="_blank" className="menu-button1">
                            <div className="menu-button2">
                                <p>
                                    business
                                    <br />
                                    solutions
                                </p>
                            </div>
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/menu-bs.svg"}
                                alt="Menu Icon - Signatures1"
                            />
                        </a>
                        <a href="https://signatures1.com/portfolio" target="_blank" className="menu-button1">
                            <div className="menu-button2 menu-button-padding">
                                <p>portfolio</p>
                            </div>
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/menu-p.svg"}
                                alt="Menu Icon - Signatures1"
                            />
                        </a>
                        <a href="https://signatures1.com/the-team" target="_blank" className="menu-button1">
                            <div className="menu-button2">
                                <p>
                                    the
                                    <br />
                                    team
                                </p>
                            </div>
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/menu-t.svg"}
                                alt="Menu Icon - Signatures1"
                            />
                        </a>
                        <a href="https://signatures1.com/who-we-are" target="_blank" className="menu-button1">
                            <div className="menu-button2">
                                <p>
                                    who <br /> we are
                                </p>
                            </div>
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/menu-wwa.svg"}
                                alt="Menu Icon - Signatures1"
                            />
                        </a>
                        <a href="https://signatures1.com/careers" target="_blank" className="menu-button1">
                            <div className="menu-button2 menu-button-padding">
                                <p>careers</p>
                            </div>
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/menu-c.svg"}
                                alt="Menu Icon - Signatures1"
                            />
                        </a>
                        <a href="https://signatures1.com/blog" target="_blank" className="menu-button1">
                            <div className="menu-button2 menu-button-padding">
                                <p>thought <br /> space</p>
                            </div>
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/thoughtspce.svg"}
                                alt="Menu Icon - Signatures1"
                            />
                        </a>
                        <a href="https://signatures1.com/contact-us" target="_blank" className="menu-button1">
                            <div className="menu-button2 menu-button-padding">
                                <p>hi!</p>
                            </div>
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/menu-h.svg"}
                                alt="Menu Icon - Signatures1"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </HeaderWrapper>
    );
};

const HeaderWrapper = styled.header`
  
position: sticky;
top: 0;
    left: 0;
    width: 100%;
    background: black;
    z-index: 99;
  
  /* .logo--signatures-vehicle {
    width:100%;
  } */
  .changing-gif {
    width: 100px!important;
}

  .logo--signatures {
    width: 60%;
    display: block;
    margin: auto;
  }
  .brand-img {
    position: relative;
    z-index: 9;
  }

  .rotate-menu-container {
    position: relative;
    height: 44px;
    width: 44px;
    z-index: 21;
  }

  .hd-index{
    position: relative;
    z-index: 22;
  }
  .menu1 {
    width: 17px;
    height: 17px;
    border: 3px solid ${({ theme }) => theme.colors.white};
    position: absolute;
    animation: ${example1} 4s infinite;
    border-radius: 50%;
    z-index: 22;
  }

  .menu2 {
    width: 17px;
    height: 17px;
    left: 30px;
    top: 0px;
    border: 3px solid ${({ theme }) => theme.colors.white};
    position: absolute;
    animation: ${example2} 4s infinite;
    border-radius: 50%;
    z-index: 22;
  }

  .menu3 {
    width: 17px;
    height: 17px;
    left: 30px;
    top: 30px;
    border: 3px solid ${({ theme }) => theme.colors.white};
    position: absolute;
    animation: ${example3} 4s infinite;
    border-radius: 50%;
    z-index: 22;
  }

  .menu4 {
    width: 17px;
    height: 17px;
    left: 0px;
    top: 30px;
    border: 3px solid ${({ theme }) => theme.colors.white};
    position: absolute;
    animation: ${example4} 4s infinite;
    border-radius: 50%;
    z-index: 22;
  }

  /* menu onclick */

  #menuButtonSection.active {
    display: block;
    transition: all 0.5s ease;
    transform: translateY(-100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
  }

  #menuButtonSection {
    display: none;
    transition: all 0.5s ease;
    transform: translateY(-100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 1 !important;
    height: auto;
    background: ${({ theme }) => theme.colors.primaryColor};
    z-index: 9;
  }

  .menu-button {
    margin: 4rem auto;
    width: 80%;
    height: 300px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
  }

  .menu-button1 {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 2rem;
    height: 100%;
    justify-content: flex-end;
    text-decoration: none;
  }

  .menu-button1 img {
    width: 80px;
  }

  .menu-button1 > img {
    transform: translateY(0%);
    transition: all 0.5s;
  }

  .menu-button1:hover > img {
    transition: all 1s;
    transform: rotate3d(0, 20, 0, 180deg);
  }

  .menu-button2 {
    transform: rotate(-90deg);
    width: 5rem;
    color: #777;
    text-align: left;
    font-weight: 500;
    transition: all 0.2s;
  }

  .menu-button2 > p {
    color: ${({ theme }) => theme.colors.white};
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    font-family: 'Josefin Sans', sans-serif;
  }



  .menu-button-padding {
    padding: 0.9rem 0;
  }

  .menu-button1:hover > .menu-button2 {
    transform: rotate(0deg);
    text-align: center;
  }

  .menu-button1:hover > .menu-button2 > p {
    transform: rotate(0deg);
    text-align: center;
  }

  .rotate-menu-container {
    position: relative;
    z-index: 999999;
    cursor: pointer;
  }



  @media (max-width: ${theme.breakpoints.medium}) {
    #menuButtonSection.active {
        top: 50px;
    }
    .menu-button {
        flex-direction: column;
        align-items: flex-start;
        /*width: max-content;*/
        height: 100%;
        gap: 1rem;
        margin: 6rem auto;
    }

    .menu-item {
        width: 100%;
        padding: 1rem 1.5rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        text-align: left;
    }

    .menu-button1 {
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: nowrap;

    }

    .menu-button1 img {
        width: 40px;
    }

    /* Adjust styles for rotated labels */
    .menu-button2 {
        transform: rotate(0deg);
        text-align: left;
        width: auto;
        color: var(--color-white);
        font-size: 16px;
        font-weight: 500;
    }

    /* Adjust styles for rotated labels on hover */
    .menu-button1:hover .menu-button2 {
        transform: rotate(0deg);
    }

  }

  @media (max-width: ${theme.breakpoints.small}) {

    .logo--signatures-vehicle {
    width: 100%!important;
}
.logo--signatures {
    width: 60%!important;
    display: block;
    margin: auto;
    display: none;
}

}

`;

export default Header;
